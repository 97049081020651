import DataSource from '../../utils/dataSource';
const utilities = require('../../utils/utilityFunctions');
const isAdPlaying = require('../../utils/ad/isAdPlaying');
const historyStorage = require('../history/history-storage');
const RelatedVideosViewPaused = require('./related-videos-view-paused');
const RelatedVideosViewEnded = require('./related-videos-view-ended');
const Playlist = require('./Playlist');

videojs.plugin('related-videos', function molRelatedSetup (opts) {
  // eslint-disable-next-line consistent-this
  const player = this;
  const options = {
    playlistHistory: true,
    ...opts
  };
  let relatedVideos = new DataSource(options.videos);
  const playlistHistory = new Playlist(player, options);
  const previousControl = player.controlBar.getChild('molPreviousVideoButton');
  const skipControl = player.controlBar.getChild('molSkipVideoButton');
  let activeView;
  const minRelatedVideos = 15;

  if (previousControl) {
    previousControl.enable();
  }

  if (skipControl) {
    skipControl.enable();
  }

  player.fetchRelatedVideos = fetchRelatedVideos;
  player.relatedVideos = relatedVideos;
  player.disableControlsListeners = disableControlsListeners;

  player.on('playerstate.enter.pauseStandby', enterRelatedVideosPaused);
  player.on('playerstate.leave.pauseStandby', hideCurrentView);

  player.on('playerstate.enter.standby', enterRelatedVideosEnded);
  player.on('playerstate.leave.standby', hideCurrentView);

  player.on('mol.play.next.video', playNextVideo);
  player.on('mol.play.previous.video', playPreviousVideo);

  player.on('reset', () => {
    relatedVideos = new DataSource(player.options().plugins['related-videos'].videos);
    player.relatedVideos = relatedVideos;
  });

  player.on('request-reset', (evt) => {
    const futureVideo = evt && evt.video || {};
    const currentVideo = utilities.extend({
      src: player.src(),
      poster: player.poster()
    }, player.options());

    if (playlistHistory.contains(futureVideo)) {
      playlistHistory.filterOut(futureVideo);
    } else {
      playlistHistory.add(currentVideo);
    }
  });

  player.trigger('related-videos-ready');

  function hideCurrentView () {
    if (activeView) {
      activeView.dispose();
      player.removeChild(activeView);
      activeView = null;
    }
  }

  function enterRelatedVideosPaused () {
    hideCurrentView();

    fetchRelatedVideos((_, videos) => {
      // This is required when quickly entering and leaving the state,
      // e.g. when autoplay -> standby -> contentWaiting ...
      //
      // A cleaner approach would be to abort the `relatedVideos.get`
      // request, though that would require adding `abort` method
      // to the `../../utils/dataSource`.
      if (player.playerState.getStateName() !== 'pauseStandby') {
        return;
      }

      activeView = new RelatedVideosViewPaused(player, {
        videos
      });

      player.addChild(activeView);
    });
  }

  function enterRelatedVideosEnded () {
    hideCurrentView();

    fetchRelatedVideos((_, videos) => {
      if (player.playerState.getStateName() !== 'standby') {
        return;
      }

      activeView = new RelatedVideosViewEnded(player, {
        videos
      });

      player.addChild(activeView);
    });
  }

  function playNextVideo () {
    if (isAdPlaying(player)) {
      return;
    }

    fetchRelatedVideos((error, relatedVideoList) => {
      if (error) {
        return;
      }

      if (relatedVideoList.length > 0) {
        changeVideo(relatedVideoList[0]);
      } else {
        player.currentTime(player.duration());
      }
    });
  }

  function playPreviousVideo () {
    if (isAdPlaying(player)) {
      return;
    }

    if (player.currentTime() <= 3 && !playlistHistory.isEmpty()) {
      changeVideo(playlistHistory.last());

      return;
    }

    player.currentTime(0);
  }

  function changeVideo (video) {
    player.resetVideo({
      video: {
        ...video,
        initialVideo: false,
        playlistClickToPlay: true
      },
      autoplay: true
    });
  }

  function fetchRelatedVideos (callback) {
    relatedVideos.get((error, relatedVideoList) => {
      if (error) {
        // eslint-disable-next-line no-console
        console.log('plugin.related-videos Error: ' + error.message);
      }

      if (!relatedVideoList || relatedVideoList.length === 0) {
        // eslint-disable-next-line no-console
        console.log('plugin.related-videos Warning: No related videos');
        // eslint-disable-next-line no-param-reassign
        relatedVideoList = [];
      }

      const historyMap = historyStorage.getMap();
      const playedRelatedVideos = [];
      const unplayedRelatedVideos = [];

      relatedVideoList.forEach((relatedVideo) => {
        const wasPlayedBefore = playlistHistory.contains(relatedVideo) || options.playlistHistory && historyMap[relatedVideo.videoId];

        if (!wasPlayedBefore &&
          relatedVideo.videoId !== player.options().videoId) {
          unplayedRelatedVideos.push(relatedVideo);
        } else {
          playedRelatedVideos.push(relatedVideo);
        }
      });

      if (unplayedRelatedVideos.length < minRelatedVideos) {
        // if we have less than the minimum related videos required
        // we concat the watched ones at the end, up to the minimum
        // number of videos required
        callback(error, unplayedRelatedVideos.concat(playlistHistory.splice(0, minRelatedVideos - unplayedRelatedVideos.length)));
      } else {
        callback(error, unplayedRelatedVideos);
      }
    }, {
      currentVideoId: player.options().videoId,
      previouslyPlayed: Object.keys(historyStorage.getMap())
    });
  }

  function disableControlsListeners () {
    player.off('mol.play.next.video', playNextVideo);
    player.off('mol.play.previous.video', playPreviousVideo);
  }
});
