const systemInfo = require('../../utils/systemInfo');
const viewport = require('../../utils/viewport');
const addContentPlayerToGlobal = require('../../utils/addContentPlayerToGlobal');
const BrandedContentEndView = require('./branded-content-end-view');

const createClickThroughBlocker = function (player, clickThroughUri) {
  const blocker = window.document.createElement('a');

  blocker.href = clickThroughUri;
  blocker.target = '_blank';
  blocker.rel = 'nofolow';
  blocker.onclick = function (e) {
    const playerState = player._states.state.get();

    // if the content is ended the click must go through
    if (playerState === 'ended' || playerState === 'standby') {
      return;
    }

    if (player.paused()) {
      player.play();

      // We prevent event propagation to avoid problems with the player's normal pause mechanism
      e.stopPropagation();

      return;
    }

    player.pause();
  };

  blocker.classList.add('mol-branded-content__blocker');

  return blocker;
};

videojs.plugin('branded-content', function (options) {
  // eslint-disable-next-line consistent-this
  const player = this;
  let blocker;
  let endStateView;

  const showEndStateView = function () {
    hideEndStateView();
    endStateView = new BrandedContentEndView(player, {
      poster: options.endStatePoster,
      clickThroughUri: options.clickThroughUri
    });

    player.addChild(endStateView);
  };

  const hideEndStateView = function () {
    if (endStateView) {
      endStateView.dispose();
      player.removeChild(endStateView);
      endStateView = null;
    }
  };

  const trackPixel = function (pixelUri) {
    const img = new Image();

    img.src = pixelUri;

    return img;
  };

  const getTrackingPixels = function () {
    if (!options.trackingPixels) {
      return null;
    }

    return options.trackingPixels.reduce((accumulator, pixel) => {
      accumulator['s' + pixel.time] = pixel.uri;

      return accumulator;
    }, {});
  };

  let trackingPixels = getTrackingPixels();
  let trackedTimes = {};
  const trackProgress = function () {
    const currentTime = Math.floor(player.currentTime()) || 0;

    if (currentTime > 0 && currentTime % 10 === 0 && !trackedTimes[currentTime]) {
      trackedTimes[currentTime] = true;
      player.trigger('brandedContentPlayer.progress');

      if (currentTime === 10 && options.impressionPixel) {
        trackPixel(options.impressionPixel);
      }
    }

    if (trackingPixels && trackingPixels['s' + currentTime]) {
      trackPixel(trackingPixels['s' + currentTime]);
      delete trackingPixels['s' + currentTime];
    }
  };

  const resetTracking = function () {
    trackedTimes = {};
    trackingPixels = getTrackingPixels();

    // IE 11 fails to properyly update the currentTime when we replay and forces the user to make 2 clicks
    if (systemInfo.browser === 'Microsoft Internet Explorer') {
      player.currentTime(0);
    }
  };

  const startPlaying = function () {
    if (!endStateView) {
      player.play();
    }
  };

  const stopPlaying = function () {
    player.pause();
  };

  // IOS video clock is very unreliable and we need a 3 seconds threshold to ensure that the user forwarded/rewound the ad
  const PROGRESS_THRESHOLD = 3;
  let previousTime = 0;
  let skipAdAttempts = 0;

  const resetPreviousTime = function () {
    previousTime = 0;
  };

  const preventAdSkip = function () {
    // Ignore ended event if the Ad time was not 'near' the end
    // and revert time to the previous 'valid' time
    if (player.duration() - previousTime > PROGRESS_THRESHOLD) {
      player.pause(true); // this reduce the video jitter if the IOS skip button is pressed
      player.play(true); // we need to trigger the play to put the video element back in a valid state
      player.currentTime(previousTime);
    }
  };

  const preventAdSeek = function () {
    const currentTime = player.currentTime();
    const progressDelta = Math.abs(currentTime - previousTime);

    if (progressDelta > PROGRESS_THRESHOLD) {
      skipAdAttempts += 1;
      if (skipAdAttempts >= 2) {
        player.pause(true);
      }

      player.currentTime(previousTime);
    } else {
      previousTime = currentTime;
    }
  };

  if (options.clickThroughUri) {
    blocker = createClickThroughBlocker(player, options.clickThroughUri);
    player.el().insertBefore(blocker, player.controlBar.el());
  }

  addContentPlayerToGlobal(player);

  player.el().classList.add('mol-branded-content');

  player.on('playerstate.enter.standby', () => {
    const videoElement = player.el().getElementsByTagName('video')[0];

    // Targets webkit browsers that open video in full screen mode (ios6, ios7).
    // Exits the full screen mode.
    if (videoElement && videoElement.webkitExitFullScreen) {
      videoElement.webkitExitFullScreen();
    }
  });

  player.on('playerstate.enter.standby', showEndStateView);
  player.on('playerstate.leave.standby', hideEndStateView);
  player.on('playerstate.leave.standby', resetPreviousTime);
  player.on('timeupdate', trackProgress);
  player.on('ended', resetTracking);

  player.muted(true);
  if (systemInfo.mobile) {
    player.el().querySelector('.vjs-tech').setAttribute('playsinline', '');
    player.on('timeupdate', preventAdSeek);
    player.on('ended', preventAdSkip);
  }

  viewport.check(player.el());
  player.el().addEventListener('viewport.enter', startPlaying);
  player.el().addEventListener('viewport.exit', stopPlaying);
});
