const FacebookButton = require('../../components/control-bar/social-controls/share/social-buttons').FacebookButton;
const TwitterButton = require('../../components/control-bar/social-controls/share/social-buttons').TwitterButton;
const PinterestButton = require('../../components/control-bar/social-controls/share/social-buttons').PinterestButton;
const MailButton = require('../../components/control-bar/social-controls/share/social-buttons').MailButton;
const ShareButton = require('../../components/control-bar/social-controls/share/share-button');

const SocialListComponent = videojs.Component.extend({
  init (player, options) {
    videojs.Component.call(this, player, options);

    this.addChild(new FacebookButton(player));
    this.addChild(new TwitterButton(player));
    this.addChild(new PinterestButton(player));
    this.addChild(new MailButton(player));
    this.addChild(new ShareButton(player));
  }
});

SocialListComponent.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'mol-related-videos-panel-social-list'
  });
};

const RelatedVideosPanelSocial = videojs.Component.extend({
  init (player, options) {
    videojs.Component.call(this, player, options);

    this.addChild(new SocialListComponent(player));
  }
});

RelatedVideosPanelSocial.prototype.createEl = function () {
  return videojs.createEl('div', {
    className: 'mol-related-videos-panel mol-related-videos-panel-social',
    innerHTML: '<span class="panel-title">Share this</span>'
  });
};

module.exports = RelatedVideosPanelSocial;
