const viewport = require('../../utils/viewport');
const systemInfo = require('../../utils/systemInfo');
const urlUtils = require('../../utils/urlUtils');
const isAutoplaySupported = require('../../utils/isAutoplaySupported');
const {getHasBeenEnabledOnPage: getHasAdhesivePlayerBeenEnabledOnPage} = require('../../integration/mol-sticky-player');
const onAmpIframeVisibilityChange = require('../../utils/onAmpIframeVisibilityChange');
const TapToUnmuteControl = require('./tap-to-unmute-control');

const isOnWiFiConnection = function () {
  /* istanbul ignore next */
  const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || {};
  const type = connection.type;

  return type === 'wifi';
};

const isInAmpIframe = (player) => player.options_.syndicationService === 'gamp';

const getAllowedConnection = ({android, iOS}) => {
  const queryOptions = urlUtils.queryStringToObj(window.location.search);

  if (queryOptions.allowedConnection) {
    return queryOptions.allowedConnection;
  }

  if (systemInfo.os.toLowerCase() === 'android') {
    return android;
  }

  if (systemInfo.os.toLowerCase() === 'ios') {
    return iOS;
  }

  return 'off';
};

videojs.plugin('autoplay-inline', function (options) {
  // eslint-disable-next-line consistent-this
  const player = this;
  const allowedConnection = getAllowedConnection(options);
  let tapToUnmuteControl;

  if (allowedConnection === 'off') {
    return;
  }

  const startPlaying = function () {
    if (getHasAdhesivePlayerBeenEnabledOnPage()) {
      return;
    }

    player.autoplayInline = true;

    if (player.duration() !== 0 && Math.abs(player.duration() - player.currentTime()) < 1) {
      player.trigger('autoplay-inline-restart');
    } else {
      tapToUnmuteControl = new TapToUnmuteControl(player);
      player.addChild(tapToUnmuteControl);
      player.muted(true);
      player.play();
    }
  };

  const stopPlaying = function () {
    if (getHasAdhesivePlayerBeenEnabledOnPage()) {
      return;
    }

    if (player._states.state.get() === 'autoplay') {
      player.trigger('autoplay-inline-cancel');
    }

    player.pause();
    player.autoplayInline = false;
    player.removeChild(tapToUnmuteControl);
  };

  const playIfAllowed = function () {
    if (
      allowedConnection === 'wifi' && isOnWiFiConnection() ||
      allowedConnection === 'data' && !isOnWiFiConnection() ||
      allowedConnection === 'all'
    ) {
      startPlaying();
    }
  };

  isAutoplaySupported((supported) => {
    if (!supported) {
      return;
    }

    if (isInAmpIframe(player)) {
      onAmpIframeVisibilityChange((visible) => {
        if (visible) {
          playIfAllowed();
        } else {
          stopPlaying();
        }
      });
    } else {
      viewport.check(player.el());
      player.el().addEventListener('viewport.enter', playIfAllowed);
      player.el().addEventListener('viewport.exit', stopPlaying);
    }
  });
});
