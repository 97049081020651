/**
 * Sometimes firefox does not trigger the 'canplay' evt.
 * This code ensure that it always gets triggered.
 */
const ensureCanplayGetsFired = (player) => {
  const timeoutId = setTimeout(() => {
    player.trigger('canplay');
  }, 2000);

  player.one('canplay', () => {
    clearTimeout(timeoutId);
  });
};

const restoreTracks = (snapshot) => {
  const suppressedTracks = snapshot.suppressedTracks;

  suppressedTracks.forEach((trackSnapshot) => {
    trackSnapshot.track.mode = trackSnapshot.mode;
  });
};

const isReadyToResume = (player) => {
  const readyState = player.readyState();

  player.log('isReadyToResume:', {readyState});
  if (readyState > 1) {
    // some browsers and media aren't "seekable".
    // readyState greater than 1 allows for seeking without exceptions
    return true;
  }
  const seekable = player.seekable();

  player.log('isReadyToResume:', {seekable: seekable && seekable.length});
  if (seekable === undefined || seekable.length > 0) {
    return true;
  }

  return false;
};

/**
 * Determine if the video element has loaded enough of the snapshot source
 * to be ready to apply the rest of the state
 */
const tryToResume = (player, snapshot, attempts) => {
  if (attempts === 0) {
    // eslint-disable-next-line no-console
    console.error('### Failed to resume the content after several attempts');

    return;
  }

  if (isReadyToResume(player)) {
    player.play();
  } else {
    setTimeout(() => tryToResume(player, snapshot, attempts - 1), 300);
  }
};

const restoreSnapshot = (player, snapshot, attempts = 20) => {
  player.log('restoreSnapshot: begin, src', player.src(), snapshot);
  const tech = player.el().querySelector('.vjs-tech');

  if (tech.src !== snapshot.src) {
    player.log('src changed:', {tech: tech.src,
      snapshot: snapshot.src});
    if (snapshot.nativePoster && snapshot.nativePoster !== tech.poster) {
      tech.poster = snapshot.nativePoster;
    }

    if ('style' in snapshot) {
      tech.setAttribute('style', snapshot.style);
    }

    // on ios7, fiddling with textTracks too early will cause safari to crash
    player.one('contentloadedmetadata', () => restoreTracks(snapshot));

    const opts = {
      src: snapshot.src,
      type: snapshot.type
    };

    player.log('new opts:', opts);

    // if the src changed for ad playback, reset it
    player.src(opts);

    // Some browsers (Safari and webkit browsers on iOS) require a call to `load` to pick up a changed source
    player.load();
  }

  if (player.currentTime() !== snapshot.currentTime) {
    player.log('currentTime changed:', player.currentTime(), snapshot.currentTime);
    player.currentTime(snapshot.currentTime);

    if (!isReadyToResume(player)) {
      // Safari browser (ipad) does not pick the current time change unless we do it after loadeddata evt.
      player.one('loadeddata', () => {
        player.currentTime(snapshot.currentTime);
      });
    }
  }

  if (Boolean(snapshot.paused)) {
    player.log('paused');

    return;
  }

  if (isReadyToResume(player) && player.currentTime() === snapshot.currentTime) {
    player.log('playing');
    player.play();
  } else {
    player.log('trying to player.load');
    player.load();
    player.one('canplay', () => tryToResume(player, snapshot, attempts));
    ensureCanplayGetsFired(player);
  }
};

module.exports = restoreSnapshot;

