/**
 * This plugin counts the number of videos the player has played in player.videoStarts;
 */
videojs.plugin('video-starts', function () {
  // eslint-disable-next-line consistent-this
  const player = this;

  player.videoStarts = 1;
  player.on('request-reset', () => {
    player.videoStarts++;
  });
});
