/* eslint-disable complexity */
import {getPageCriteria, getPageMetadata} from '@mol-fe/mol-fe-page-metadata';
import urlUtils from '../utils/urlUtils';
import {debounce} from '../utils/utilityFunctions';
import TapToUnmuteControl from '../plugins/autoplay-inline/tap-to-unmute-control';
import ControlsOverlayPanel from '../plugins/video-advertising-mode/controls-overlay-panel';

let closeHandler = null;
let hasBeenEnabledOnPage = false;

export const getHasBeenEnabledOnPage = () => hasBeenEnabledOnPage;

const ADHESIVE_STYLE_VARIANTS = ['default', 'letterbox', 'closebottom'];

export const runCloseHandlerIfOpen = () => {
  if (!closeHandler) {
    return;
  }

  closeHandler();
};

export const initStickyPlayer = () => {
  let hasMadeAPlayerSticky = false;

  try {
    const {renderPlatform} = getPageMetadata();
    const {isMobile, pageType} = getPageCriteria();

    if (renderPlatform === 'mobile' && pageType === 'article' && isMobile && 'IntersectionObserver' in window) {
      const queryOptions = urlUtils.queryStringToObj(window.location.search);
      const firstVideo = document.querySelector('video[data-opts]');
      const otherPlayers = Array.from(document.querySelectorAll('video[data-opts]')).slice(1).map((videoEl) => videoEl.player);
      const player = firstVideo.player;
      const isEnabledFromQueryString = queryOptions.debugAdhesiveVideoplayer === 'true';
      const pluginConfig = player.options().flyawayPlayer;
      const isEnabledForPlayer = pluginConfig && pluginConfig.enabled;
      const flyawayFromParagraph = pluginConfig && pluginConfig.fromParagraph;
      const playerEl = player.el();
      const containerEl = playerEl.parentNode;
      const previousInactivityTimeout = player.options_.inactivityTimeout;
      const previousControl = player.controlBar.getChild('molPreviousVideoButton');
      const playToggle = player.controlBar.children_.find(({el_}) => el_.classList.contains('vjs-play-control'));
      const skipControl = player.controlBar.getChild('molSkipVideoButton');
      const progressBar = player.controlBar.children_.find(({el_}) => el_.classList.contains('vjs-progress-control'));
      const volumeMenu = player.controlBar.children_.find(({el_}) => el_.classList.contains('vjs-volume-menu-button'));
      const timerControls = player.controlBar.children_.find(({el_}) => el_.classList.contains('vjs-timer-controls'));
      let tapToUnmuteControl;
      let controlsPanel;
      let userHasSkipped = false;
      let hasEndedOnce = false;
      let hasPlayedContent = false;

      let styleVariant = 'letterbox';

      if (pluginConfig && pluginConfig.adhesiveStyleVariant && ADHESIVE_STYLE_VARIANTS.includes(pluginConfig.adhesiveStyleVariant)) {
        styleVariant = pluginConfig.adhesiveStyleVariant;
      }

      if (queryOptions.adhesiveStyleVariant && ADHESIVE_STYLE_VARIANTS.includes(queryOptions.adhesiveStyleVariant)) {
        styleVariant = queryOptions.adhesiveStyleVariant;
      }

      if (!isEnabledForPlayer && !isEnabledFromQueryString) {
        return;
      }

      player.one('playerstate.enter.contentPlaying', () => {
        hasPlayedContent = true;
      });

      const makePlayerSticky = () => {
        if (hasMadeAPlayerSticky) {
          return;
        }

        const placeholderEl = document.createElement('div');

        placeholderEl.classList.add('vjs-editorial-sticky-placeholder');
        placeholderEl.style.height = `${containerEl.offsetHeight}px`;

        hasMadeAPlayerSticky = true;
        containerEl.parentNode.classList.add(`variant-${styleVariant}`);
        containerEl.parentNode.classList.add('vjs-editorial-sticky');
        containerEl.parentNode.parentNode.appendChild(placeholderEl);

        // Chromeless
        player.options_.inactivityTimeout = 3000;
        player.userActive(false);
        containerEl.classList.add('mol-video-advertising-mode');
        controlsPanel = new ControlsOverlayPanel(player, {});
        player.addChild(controlsPanel);

        const touchActiveHandler = () => {
          player.userActive(true);
        };

        firstVideo.addEventListener('touchstart', touchActiveHandler);

        player.isPlayingAdhesive = true;
        player.autoplayBeforeAdhesive = false;
        player.play();

        const closeButton = document.createElement('button');

        closeButton.classList.add('editorial-sticky-close');

        if (styleVariant === 'closebottom') {
          closeButton.innerHTML = 'Close';
        }

        const firstPicture = document.querySelector('.scrollable-content > article > figure');
        const scrollableElement = document.querySelector('.scrollable-content') || window;
        const scrollHandler = debounce(() => {
          if (!player.isPlayingAdhesive) {
            return;
          }

          const {y: yPosPicture} = firstPicture.getBoundingClientRect();
          const pageHasSmartBanner = document.body.classList.contains('smart-banner');
          const topThreshold = pageHasSmartBanner ? 173 : 93;

          if (yPosPicture > topThreshold) {
            containerEl.parentNode.style.willChange = 'transform';
            containerEl.parentNode.style.transform = `translateY(${yPosPicture}px)`;
          } else {
            containerEl.parentNode.style.transform = '';
            containerEl.parentNode.style.willChange = '';
          }
        }, 5);

        scrollableElement.addEventListener('scroll', scrollHandler);

        closeHandler = () => {
          scrollableElement.removeEventListener('scroll', scrollHandler);
          firstVideo.removeEventListener('touchstart', touchActiveHandler);
          placeholderEl.remove();
          containerEl.parentNode.classList.remove('vjs-editorial-sticky');
          containerEl.parentNode.style.transform = '';
          containerEl.parentNode.style.willChange = '';
          containerEl.classList.remove('mol-video-advertising-mode');
          player.removeChild(controlsPanel);
          player.options_.inactivityTimeout = previousInactivityTimeout;
          player.isPlayingAdhesive = false;
          player.pause();
          closeButton.remove();
          closeHandler = null;

          player.controlBar.addChild(previousControl);
          player.controlBar.addChild(playToggle);
          player.controlBar.addChild(skipControl);
          player.controlBar.addChild(progressBar);
          player.controlBar.addChild(volumeMenu);
          player.controlBar.addChild(timerControls);

          player.one('play', () => {
            if (player.muted()) {
              player.muted(false);
            }
          });
        };

        closeButton.addEventListener('click', runCloseHandlerIfOpen);

        // Close adhesive if content video ends playing, and user did not press skip
        player.one('mol.play.next.video', () => {
          userHasSkipped = true;
        });
        player.on('ended', () => {
          if (!userHasSkipped && !hasEndedOnce && hasPlayedContent && player.playerState.getStateName() === 'ended') {
            hasEndedOnce = true;
            player.autoClosedSticky = true;
            player.resetVideo({
              autoplay: false,
              video: player.options()
            });
            player.el().classList.add('has-autoclosed-sticky');
            player.one('play', () => {
              player.autoClosedSticky = false;
            });
            runCloseHandlerIfOpen();
          }
        });

        for (const otherPlayer of otherPlayers) {
          otherPlayer.one('play', runCloseHandlerIfOpen);
        }

        try {
          if (window.DM && window.DM.molFeGallery && window.DM.molFeGallery.addBeforeOpenHandler) {
            window.DM.molFeGallery.addBeforeOpenHandler(runCloseHandlerIfOpen);
          }
        } catch (error) {
          // Ignore DM errors
        }

        containerEl.parentNode.appendChild(closeButton);
      };

      let wasInViewport = false;
      let paragraphObserver = null;

      const autoplayInPlace = () => {
        player.muted(true);
        firstVideo.muted = true;
        firstVideo.setAttribute('playsinline', 'playsinline');
        firstVideo.setAttribute('muted', true);
        tapToUnmuteControl = new TapToUnmuteControl(player);
        player.addChild(tapToUnmuteControl);
        player.autoplayBeforeAdhesive = true;
        player.play();
      };

      const handleIntersect = (entries, observer) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            if (!wasInViewport) {
              wasInViewport = true;
              autoplayInPlace();

              if (paragraphObserver) {
                paragraphObserver.disconnect();
              }
            }
          } else if (wasInViewport) {
            makePlayerSticky();
            observer.disconnect();
          }
        }
      };
      const videoElObserver = new IntersectionObserver(handleIntersect, {
        threshold: [0.5]
      });

      videoElObserver.observe(containerEl);

      if (flyawayFromParagraph) {
        const articleParagraphs = document.querySelectorAll('.scrollable-content > article > p.mol-para-with-font');
        const paragraph = articleParagraphs && articleParagraphs[flyawayFromParagraph];

        if (paragraph) {
          const handleParagraphIntersect = (entries, observer) => {
            for (const entry of entries) {
              if (entry.isIntersecting) {
                autoplayInPlace();
                makePlayerSticky();
                observer.disconnect();
                videoElObserver.disconnect();
              }
            }
          };

          paragraphObserver = new IntersectionObserver(handleParagraphIntersect, {
            threshold: [0.5]
          });

          paragraphObserver.observe(paragraph);
        }
      }

      hasBeenEnabledOnPage = true;
      containerEl.parentNode.classList.add('vjs-possible-editorial-sticky');
    }
  } catch (error) {
    // ignore any errors for now
  }
};
