const async = require('../../utils/async');

videojs.plugin('reset', function resetPluginSetup () {
  // eslint-disable-next-line consistent-this
  const player = this;

  player.on('request-reset', (evt) => {
    const video = evt.video;

    const autoplay = evt.autoplay;

    // give the player tracking time to send abort event with old data;
    async.defer(() => {
      player.trigger({type: 'reset-sources',
        video,
        autoplay});
    });
  });

  player.on('reset-sources', (evt) => {
    const video = evt.video;

    const autoplay = evt.autoplay;

    if (!video.src) {
      // eslint-disable-next-line no-console
      console.log('reset-resources request ignored. The passed video has no sources', video);

      return;
    }

    player.playingRecommendedVideo = true;
    player.autoplay(false);
    player.pause();
    player.src(video.src);
    player.poster(video.poster);
    player.playlistClickToPlay = Boolean(video.playlistClickToPlay);

    player.options(sanitiseOpts(video || {}));

    if (video.unmute) {
      player.muted(false);
    }

    if (video.plugins && video.plugins.tracking && !video.plugins.tracking.dmtvEpisode) {
      // Needs to be reset
      player.options().plugins.tracking.dmtvEpisode = null;
    }

    notifyReset();

    player.on('reset', () => {
      if (autoplay && !player.autoClosedSticky) {
        // We need to execute this asynchronously to give time to the other plugins to reset.
        async.defer(() => {
          player.play();
        });
      }
    });
  });

  player.resetVideo = function (opts) {
    player.trigger({
      type: 'request-reset',
      video: opts.video,
      autoplay: opts.autoplay
    });
  };

  function sanitiseOpts (opts) {
    // The platform is not going to change when we reset and we need to ensure that it stays the same after the reset
    delete opts.isMobile;

    return opts;
  }

  function notifyReset () {
    async.defer(() => {
      player.trigger('reset');
    });
  }
});
