const dom = require('../../utils/dom');

videojs.plugin('post-content', function postContent (opts) {
  // eslint-disable-next-line consistent-this
  const player = this;

  if (!player.options().isMobile) {
    const afterVideoEl = document.getElementById(opts.id);
    let afterVideoElParent;
    let n;
    let tags;
    let afterVideoFormEl;
    let thankYouEl;
    let voted = false;

    if (afterVideoEl) {
      afterVideoElParent = afterVideoEl.parentNode;
    }

    /** If there is a post-video element on the page **/
    if (afterVideoEl && opts.id && afterVideoElParent) {
      afterVideoFormEl = document.querySelector('#' + opts.id + ' form');
      thankYouEl = document.createElement('p');
      thankYouEl.setAttribute('id', 'video-poll-feedback');
      thankYouEl.className = 'poll-question poll-question-feedback';
      thankYouEl.innerHTML = 'Thanks for the feedback';

      tags = afterVideoElParent.getElementsByTagName(opts.onCloseTriggerTag);
      n = tags.length;

      for (let i = 0; i < n; i++) {
        // add this class to only the first element
        if (i === 0) {
          dom.addClass(tags[i], 'answer-label-first');
        }

        // eslint-disable-next-line no-loop-func
        dom.addEventListener(tags[i], 'click', () => {
          voted = true;

          // hide the form
          dom.addClass(afterVideoFormEl, 'vjs-hidden');

          // change text
          dom.prependChild(afterVideoEl, thankYouEl);

          // reset poll to original state
          setTimeout(() => {
            dom.removeClass(afterVideoElParent, 'vjs-shown');
            afterVideoEl.removeChild(thankYouEl);
            dom.removeClass(afterVideoFormEl, 'vjs-hidden');
          }, 3000);
        });
      }

      player.on('ended', () => {
        // removing some injected inline-styles from general polls handler;
        if (!voted) {
          setTimeout(() => {
            afterVideoFormEl.removeAttribute('style');
            dom.addClass(afterVideoElParent, 'vjs-shown');
          }, 500);
        }
      });

      player.on('destroyed', () => {
        dom.removeClass(afterVideoElParent, 'vjs-shown');
      });
    }
  }
});
