const dom = require('../utils/dom');
const utilities = require('../utils/utilityFunctions');
const PlayersSetup = require('./players-setup');

const VIDEOJSCLASS = 'video-js';

const molFeVideoplayer = function (el) {
  const setup = new PlayersSetup();

  setup.run(el);
};

molFeVideoplayer.init = function (el) {
  const setup = new PlayersSetup();

  return setup.run(el);
};

molFeVideoplayer.initializeVideo = function (mediaEl, config) {
  const setup = new PlayersSetup();

  return setup.initializeVideo(mediaEl, config);
};

molFeVideoplayer.clone = function (el, override) {
  const player = el.player;
  let opts = PlayersSetup.getMediaOpts(el);

  if (!player || !opts) {
    return;
  }

  if (override) {
    opts = utilities.extend({}, opts, override);
  }

  const video = document.createElement('video');

  video.className = 'video-js';
  video.setAttribute('width', player.width());
  video.setAttribute('height', player.height());
  video.setAttribute('controls', 'true');
  video.setAttribute('preload', 'none');
  video.setAttribute('data-opts', JSON.stringify(opts));

  if (player.autoplay()) {
    video.setAttribute('autoplay', 'autoplay');
  }

  utilities.forEach(player.options().sources, (s) => {
    const src = document.createElement('source');

    src.setAttribute('src', s.src);
    src.setAttribute('width', s.width);
    src.setAttribute('height', s.height);
    src.setAttribute('type', s.type);
    video.appendChild(src);
  });

  // eslint-disable-next-line consistent-return
  return video;
};

molFeVideoplayer.destroy = function (el) {
  molFeVideoplayer.forEachPlayer(el, function () {
    this.trigger('request-dispose');
  });
};

molFeVideoplayer.forEachPlayer = function (el, cb) {
  let vids;

  /* eslint-disable no-param-reassign */
  if (typeof el === 'function') {
    cb = el;
    el = undefined;
  }

  el = el || document;
  /* eslint-enable no-param-reassign */

  if (el !== document && dom.hasClass(el, VIDEOJSCLASS)) {
    vids = [el];
  } else {
    vids = el.querySelectorAll('.' + VIDEOJSCLASS);
  }

  utilities.forEach(vids, (mediaEl) => {
    if (mediaEl.player) {
      cb.apply(mediaEl.player);
    }
  });
};

const events = {};

molFeVideoplayer.on = function (name, func) {
  if (!(name in events)) {
    events[name] = [];
  }
  events[name].push(func);
};

molFeVideoplayer.off = function (name, func) {
  if (!(name in events)) {
    return;
  }
  if (typeof func === undefined) {
    delete events[name];
  } else {
    events[name] = events[name].filter((f) => func !== f);
  }
};

molFeVideoplayer.trigger = function (name, evt) {
  const cbs = name in events ? events[name] : [];

  for (let i = 0, len = cbs.length; i < len; i++) {
    cbs[i](evt);
  }
};

module.exports = molFeVideoplayer;
