const BrandedContentEndView = videojs.Component.extend({
  init (player, options) {
    videojs.Component.call(this, player, options);
  }
});

BrandedContentEndView.prototype.createEl = function () {
  const options = this.options();

  const viewElement = videojs.createEl('div', {
    className: 'mol-branded-content__end-view'
  });

  const clickThroughUri = options.clickThroughUri ? options.clickThroughUri : '';
  const target = options.clickThroughUri ? '_blank' : '';

  viewElement.innerHTML =
    '<a class="mol-branded-content__end-view-blocker" href="' + clickThroughUri + '" target="' + target + '">' +
      '<img class="mol-branded-content__end-view-poster" src="' + options.poster + '">' +
    '</a>';

  return viewElement;
};

module.exports = BrandedContentEndView;
