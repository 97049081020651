const systemInfo = require('./systemInfo');
const utilities = require('./utilityFunctions');
const isElementInViewPort = require('./isElementInViewPort');

const createEvent = function (eventName) {
  const evt = document.createEvent('Event');

  evt.initEvent(eventName, true, true);

  return evt;
};

const checkedItems = [];

const checkElement = function (element) {
  checkedItems.push({
    element,
    inViewport: false
  });

  let scrollableContent = window;

  if (systemInfo.mobile) {
    scrollableContent = document.querySelector('.scrollable-content') || window;
  }

  if (document.readyState === 'complete') {
    checkPosition();
  } else {
    window.addEventListener('DOMContentLoaded', checkPosition, false);
    window.addEventListener('load', checkPosition, false);
  }
  scrollableContent.addEventListener('scroll', checkPosition, false);
  window.addEventListener('resize', checkPosition, false);
  document.addEventListener('visibilitychange', checkPosition, false);
};

const checkPosition = utilities.debounce(() => {
  checkedItems.forEach((item) => {
    const element = item.element;

    // actual check is done async to avoid performance penalty
    setTimeout(() => {
      const elementInViewPort = isElementInViewPort(element) && !document.hidden;

      if (elementInViewPort && !item.inViewport) {
        item.inViewport = true;
        element.dispatchEvent(createEvent('viewport.enter'));
      } else if (!elementInViewPort && item.inViewport) {
        item.inViewport = false;
        element.dispatchEvent(createEvent('viewport.exit'));
      }
    }, 0);
  });
}, 100);

module.exports = {
  check: checkElement,
  checkedElements: checkedItems
};
