const ControlsOverlayPanel = require('./controls-overlay-panel');
const isCircularPlaylist = (player) =>
  !player.options_.plugins['related-videos'] ||
  Array.isArray(player.options_.plugins['related-videos'].videos);

videojs.plugin('video-advertising-mode', function () {
  // eslint-disable-next-line consistent-this
  const player = this;

  player.videoAdvertisingMode = true;
  player.options_.inactivityTimeout = 3000;
  player.userActive(false);

  const updateRelatedVideoList = (nextVideo) => {
    player.relatedVideos.get((_, relatedVideoList) => {
      const idx = relatedVideoList.findIndex((video) => video.referenceId === nextVideo.referenceId);

      relatedVideoList.splice(idx, 1);
      relatedVideoList.push(nextVideo);

      player.relatedVideos.update(relatedVideoList);
    });
  };

  player.on('request-reset', (evt) => {
    const video = evt && evt.video;

    if (video && isCircularPlaylist(player)) {
      // update related video list so that it behaves as a circular list
      updateRelatedVideoList(video);
    }

    player.trigger('playlistItem');
  });

  const initPlaylist = () => {
    player.fetchRelatedVideos((error, relatedVideoList) => {
      if (error) {
        throw error;
      }

      const video = relatedVideoList[0];

      player.trigger({
        type: 'request-reset',
        video: {
          ...video
        },
        autoplay: true
      });

      // In video advertising mode the first video comes from the fetched list
      // which forces the player to reset the videoStarts counter
      player.videoStarts = 1;
    });
  };

  player.el().closest('.vjs-video-container').classList.add('mol-video-advertising-mode');

  player.on('videoAd.start', () => {
    player.trigger('adStart');
  });

  player.on('videoAd.impression', () => {
    player.trigger('adImpression');
  });

  player.on('videoAd.error', () => {
    player.trigger({
      type: 'adError',
      code: player.adUnit && player.adUnit.errorCode
    });
  });

  player.on('timeupdate', () => {
    player.trigger({
      type: 'time',
      duration: player.duration(),
      position: player.currentTime()
    });
  });

  player.ready(() => {
    const controlsPanel = new ControlsOverlayPanel(player, {});

    player.addChild(controlsPanel);

    if (player.fetchRelatedVideos) {
      initPlaylist();
    } else {
      player.on('related-videos-ready', initPlaylist);
    }

    setTimeout(() => {
      player.trigger('viewable');
    });
  });
});

