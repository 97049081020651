import {getPageCriteria} from '@mol-fe/mol-fe-page-metadata';
import BidmaxAuction from './BidmaxAuction';
const browserSupportsVideoAds = require('../../utils/ad/browserSupportsVideoAds');
const getMolAdTagOptions = require('../../utils/ad/getMolAdTagOptions');
const requestAdTagUrl = require('../../utils/ad/requestAdTagUrl');
const systemInfo = require('../../utils/systemInfo');
const searchOptions = require('./searchOptions');
const getAdSchedule = require('./getAdSchedule');
const getNextAdConfig = require('./getNextAdConfig');
const preparePlayerForScheduledAds = require('./preparePlayerForScheduledAds');

require('./video-ads-plugin');

const adsAreSupported = () => {
  const {
    nonAdservable = false
  } = getPageCriteria();

  if ('isAdFreeEntitled' in window && window.isAdFreeEntitled()) {
    return false;
  }

  return !nonAdservable && browserSupportsVideoAds();
};

videojs.plugin('ads-setup', function molVastSetup (opts) {
  // eslint-disable-next-line consistent-this
  const player = this;
  let cachedAdTagOpts = {};
  let schedule;
  let nextVideoSchedule;

  // Requested by commercial on MOL-15712
  const unmutePlayer = function () {
    player.muted(false);
  };

  player.on('fullscreenchange', unmutePlayer);
  player.on('flyout-expand', unmutePlayer);

  // We need the player to be ready in order to properly generate the ad tag.
  player.ready(() => {
    const platform = player.videoAdvertisingMode ? 'chromeless' : 'dmplayer';
    let options = {
      vpaidEnabled: false,
      platform,
      ...opts,
      domain: player.options().isEmbedded ? 'notdailymail' : 'dailymail',
      scor: Date.now(),
      ...searchOptions()
    };

    schedule = getAdSchedule(options);

    options.adsEnabled = adsAreSupported() && Boolean(options.adsEnabled);
    player.adsEnabled = options.adsEnabled;

    let counter = 0;
    const getAdTagUrl = (player, adConfig) => {
      const auction = options.bidmax ? new BidmaxAuction(player) : undefined;

      const adTagOpts = getMolAdTagOptions(player, {
        ...adConfig
      });

      const id = counter++;
      const log = (...args) => player.log(`[ads-auction-${id}]`, ...args);

      const timeoutPromises = [new Promise((resolve) => setTimeout(resolve, 5000))];
      const userActionTimeout = adConfig.userActionTimeout;

      let finished = false;

      if (!adConfig.autoplay && userActionTimeout) {
        const userActionPromiseTimeout = new Promise((resolve) => {
          log('using user action timeout to cancel auction in', userActionTimeout, 'ms');
          const cancel = player.preEventHook('play', (next) => {
            log('play');
            cancel();

            if (finished) {
              log('auction already finished, not installing timeout');
            } else {
              log('setting up after user action timeout of', userActionTimeout, 'ms');
              setTimeout(() => {
                if (finished) {
                  log('auction finished just before being forced to');
                } else {
                  log('forcing auction end');
                }
                resolve();
              }, userActionTimeout);
            }
            next();
          }, 10);
        });

        timeoutPromises.push(userActionPromiseTimeout);
      } else {
        log('not using user action timeout');
      }

      log('getting video tags', {...adTagOpts});

      const videoTagsResp = requestAdTagUrl({
        ...adTagOpts,
        timeoutPromise: Promise.race(timeoutPromises)
      });
      const ad = {
        auction,
        videoTags: videoTagsResp
      };

      videoTagsResp.then(({onImpression, onFailure}) => {
        finished = true;
        if (auction) {
          auction.setVideoEventHandlers(onImpression, onFailure);
        }
        log('auction finished');
      });

      return ad;
    };

    const getAdTag = () => {
      const currentTime = player.currentTime();
      const nextAdConfig = getNextAdConfig(schedule, {
        currentTime,
        duration: player.options_.duration / 1000
      });

      if (!cachedAdTagOpts[nextAdConfig.offset]) {
        cachedAdTagOpts[nextAdConfig.offset] = getAdTagUrl(player, {
          sync: systemInfo.mobile,
          ...player.options(),
          ...nextAdConfig,
          videoStarts: player.videoStarts
        });
      }

      const {
        auction,
        videoTags
      } = cachedAdTagOpts[nextAdConfig.offset];

      if (auction) {
        auction.ready();
      }

      return videoTags.then(({buildAdTagUrl}) => buildAdTagUrl);
    };

    player.adSDK = options.useIMASDK ? 'IMA' : 'MOL';
    player['video-ads-plugin']({
      ...options,
      timeout: options.adsCancelTimeout,
      bannerFallback: options.bannerFallback || false,
      getAdTag
    });

    if (options.earlyBidding && !options.autoplay) {
      getAdTag();
    }

    player.on(
      'request-reset', ({video}) => {
        options = {
          ...options,
          ...video.plugins['ads-setup']
        };

        schedule = nextVideoSchedule || getAdSchedule(options);
        nextVideoSchedule = null;
        options.adsEnabled = adsAreSupported() && Boolean(options.adsEnabled);
        player.adsEnabled = options.adsEnabled;
        cachedAdTagOpts = {
          0: cachedAdTagOpts.nextVideoPreroll
        };

        player.trigger({
          type: 'videoAds.reset',
          options
        });
      });

    player.on(
      'videoAd.contentResumeRequested',
      () => {
        player.one('timeupdate', () => {
          /* istanbul ignore else */
          if (schedule && !schedule.addedToPlayer) {
            preparePlayerForScheduledAds(player, schedule);
            schedule.addedToPlayer = true;
          }

          player.trigger({type: 'videoAd.prefetchAdTag'});
        });
      }
    );

    player.on(['mol.play.previous.video', 'mol.play.next.video'], () => {
      cachedAdTagOpts.nextVideoPreroll = undefined;
    });

    player.on('seeked', () => {
      player.trigger({type: 'videoAd.prefetchAdTag'});
    });

    player.on('videoAd.prefetchAdTag', async () => {
      const nextAdMinCurrentTime = player.currentTime() + 1;
      const nextAdConfig = getNextAdConfig(schedule, {
        currentTime: nextAdMinCurrentTime,
        duration: player.duration()
      });

      if (options.adsEnabled && nextAdConfig && !cachedAdTagOpts[nextAdConfig.offset]) {
        cachedAdTagOpts[nextAdConfig.offset] = getAdTagUrl(player, {
          sync: false,
          ...player.options(),
          ...nextAdConfig,
          videoStarts: player.videoStarts
        });
      }

      if (player.nextVideo && !cachedAdTagOpts.nextVideoPreroll) {
        const video = await player.nextVideo;
        const nextVideOptions = {
          ...options,
          ...video.plugins['ads-setup']
        };
        const adsEnabled = adsAreSupported() && Boolean(nextVideOptions.adsEnabled);

        if (adsEnabled) {
          nextVideoSchedule = getAdSchedule(nextVideOptions);
          const nextVideoPrerollConfig = getNextAdConfig(nextVideoSchedule, {
            currentTime: 0,
            duration: video.duration / 1000
          });

          cachedAdTagOpts.nextVideoPreroll = getAdTagUrl(player, {
            sync: false,
            ...player.options(),
            ...video,
            ...nextVideoPrerollConfig,
            adsEnabled,
            autoplay: true,
            videoStarts: player.videoStarts + 1
          });
        }
      }
    });
  });
});
