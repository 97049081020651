import urlUtils from '../utils/urlUtils';
import {initStickyPlayer} from './mol-sticky-player';

// this code initialize any video in a modal; whether built dynamically or inPage (set in the options)
DM.later('bundle', () => {
  document.addEventListener('ez-modal-after-load', (evt) => {
    const el = evt.ui.modal.get(0);

    if (el) {
      DM.molFeVideoplayer.init(el);
    }
  });

  document.addEventListener('ez-modal-after-open', (evt) => {
    const el = evt.ui.modal.get(0);
    const opt = evt.opt;

    if (opt.modalType && opt.modalType === 'video-overlay') {
      DM.molFeVideoplayer.forEachPlayer(el, function () {
        if (this.options().inPage) {
          this.play();
        }
      });
    }
  });

  document.addEventListener('ez-modal-before-close', (evt) => {
    const el = evt.ui.modal.get(0);
    const opt = evt.opt;
    let container;

    if (opt.modalType && opt.modalType === 'video-overlay') {
      DM.molFeVideoplayer.forEachPlayer(el, function () {
        this.pause();
      });
    } else if (el) {
      DM.molFeVideoplayer.destroy(el);
      container = el.querySelector('.vjs-video-container');

      if (container) {
        container.removeAttribute('id'); // remove conflicting id
      }
    }
  });

  const getJSON = function (url, callback) {
    const request = new XMLHttpRequest();

    function handleResponse () {
      callback(JSON.parse(request.responseText));
    }

    request.onload = handleResponse;
    request.open('GET', url, true);
    request.send();
  };

  // OPEN VIDEO INTEGRATION
  document.addEventListener('click', (evt) => {
    const withAttr = evt.target.closest('[data-videoplayer-open-playerid]');

    if (!withAttr) {
      return;
    }

    const opts = withAttr.getAttribute('data-videoplayer-open-opts');
    let newvideo;

    evt.preventDefault();
    const id = withAttr.getAttribute('data-videoplayer-open-playerid');
    const player = getVideoPlayerFromId(id, withAttr);

    if (!player || typeof opts !== 'string') {
      return;
    }

    try {
      newvideo = JSON.parse(opts);
    } catch (error) {
      // opts is probably a URL
      getJSON(urlUtils.appendGeolocation(opts), changeVideo);

      return;
    }

    // opts was a deserializable object
    changeVideo(newvideo);

    function changeVideo (video) {
      player.resetVideo({
        video: {
          ...video,
          initialVideo: false
        },
        autoplay: true
      });
    }
  });

  // PLAYLIST INTEGRATION
  document.addEventListener('click', (evt) => {
    const withAttr = evt.target.closest('[data-videoplayer-playlist-playerid]');

    if (!withAttr) {
      return;
    }

    evt.preventDefault();
    const id = withAttr.getAttribute('data-videoplayer-playlist-playerid');
    const player = getVideoPlayerFromId(id, withAttr);

    if (!player) {
      return;
    }

    const playlistNumber = withAttr.getAttribute('data-videoplayer-playlist-number');

    if (playlistNumber === 'next') {
      player.next();
    } else if (playlistNumber === 'previous') {
      player.previous();
    } else {
      player.gotoVideo(parseInt(playlistNumber, 10));
    }
  });

  // MOL-GALLERY INTEGRATION
  const galleryModal = document.querySelector('#mobileGalleryModal');

  if (galleryModal) {
    galleryModal.addEventListener('open', () => {
      DM.molFeVideoplayer.trigger('pauseAll', {});
    });
  }
});

const getVideoPlayerFromId = function (id, closeTo) {
  const selector = '#' + id + ' [data-opts]';
  let nodes = document.querySelectorAll(selector);

  if (!nodes.length) {
    return;
  }

  if (nodes.length > 1) {
    // eslint-disable-next-line no-param-reassign, no-cond-assign
    while (closeTo = closeTo.parentNode) {
      nodes = closeTo.querySelectorAll(selector);
      if (nodes.length > 0) {
        break;
      }
    }
  }

  // get the player form the node
  const playerFromNode = nodes[0].querySelector('[data-opts]');

  // eslint-disable-next-line consistent-return
  return playerFromNode && playerFromNode.player || closeTo.querySelector(selector).player;
};

// eslint-disable-next-line complexity
DM.later(['DOM_READY', 'videoplayer-ready'], () => {
  try {
    if (!window.PageCriteria.isMobile && DM.getPageMetadata().articleId && window.location.hash === '#video') {
      const firstVideo = document.querySelector('video[data-opts]');

      if (firstVideo) {
        firstVideo.player.ready(() => {
          DM.molFeDomHelpers.scrollUtils.scrollToElement(firstVideo);
          DM.molFeDomHelpers.scrollUtils.scrollBy(0, 0 - window.innerHeight / 3);
        });
      }
    }
  } catch (error) {
    // empty on purpose
  }

  initStickyPlayer();
});
