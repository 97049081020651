/*
  CMP (Consent Management Platform) integration plugin

  Main repo => https://github.com/MailOnline/mol-ads-cmp
  (based on the IAB.europe spec)
  For more info see:
  https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework
  https://github.com/MailOnline/mol-ads-cmp#video
*/
videojs.plugin('cmp', function cmp (options) {
  if (!options.enabled) {
    return;
  }

  const win = window;

  win.__cmp('rendered', null, () => {
    const playButton = document.querySelector('.mol-ads-cmp--banner .mol-ads-cmp--btn-primary');

    if (playButton) {
      playButton.addEventListener('click', this.play.bind(this));
    }
  });

  win.__cmp('hasChoiceBeenMade', null, (chosen) => {
    if (chosen) {
      return;
    }

    this.one('play', () => {
      win.__cmp('consentToAll');
    });
  });
});
