videojs.plugin('portrait-mode', function sourcesPlugin () {
  // eslint-disable-next-line consistent-this
  const player = this;
  const {
    autoplay,
    fbiaEmbed,
    flyaway,
    isMobile,
    original,
    syndicationService
  } = player.options();

  if (!isMobile || !original || fbiaEmbed || autoplay || flyaway || syndicationService === 'fbia' || syndicationService === 'gamp') {
    return;
  }

  player.ready(() => {
    const removeHook = player.preEventHook('play', (next) => {
      removeHook();

      if (Array.isArray(player.renditions)) {
        const rendition = player.renditions[0];

        if (rendition.height > rendition.width) {
          player.requestFullscreen();
        }
      }

      next();
    });
  });
});
