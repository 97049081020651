function isEditorialContentPlayer (player) {
  const plugins = player.options().plugins;

  return Boolean(plugins) && Boolean(plugins['editorial-content']);
}

function isVideoAdvertisementPlayer (player) {
  const plugins = player.options().plugins;

  return Boolean(plugins) && Boolean(plugins['video-advertising-mode']);
}

// eslint-disable-next-line complexity
function isAutoplaying (player) {
  if (player.playlistClickToPlay) {
    return false;
  }

  return player.forceFlyOutInProgress ||
         player.autoplayInline ||
         player.keyMomentAutoplay ||
         ('playlistCounter' in player ? player.playlistCounter > 0 : false) ||
         ('autoplayIndex' in player ? player.autoplayIndex > 0 : false) ||
         isEditorialContentPlayer(player) ||
         isVideoAdvertisementPlayer(player) ||
         Boolean(player.isPlayingAdhesive) ||
         Boolean(player.autoplayBeforeAdhesive);
}

module.exports = isAutoplaying;
